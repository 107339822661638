import { Row, Col, Image, DatePicker, Empty } from "antd";
import { useCallback, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { landingWorkerInfoList } from "@/api/contact-info";
import styles from "./index.module.less";
import moment from "moment";
import { getAge, getConstellation, getZodiac } from "@/utils/day";
import avatar from "@/asset/avatar.svg";

const Content = (props) => {
  const { t } = useTranslation();

  const queryDataRef = useRef({
    page: 1,
    size: 20,
    effectiveDate: moment(),
  });

  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [effectiveDate, setEffectiveDate] = useState(moment());

  const getList = useCallback(async () => {
    try {
      const data = await landingWorkerInfoList({
        ...queryDataRef.current,
        effectiveDate: queryDataRef.current.effectiveDate.format("YYYY-MM-DD"),
      });
      setList((val) =>
        queryDataRef.current.page === 1
          ? data.data.list
          : [...val, ...data.data.list]
      );
      setTotal(data.data.total);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onChangeDate = useCallback(
    (date) => {
      queryDataRef.current = {
        ...queryDataRef.current,
        effectiveDate: date,
        page: 1,
      };
      setEffectiveDate(date);
      getList();
    },
    [getList]
  );

  const onLoadMore = useCallback(async () => {
    console.log("onLoadMore");
    if (list.length < total) {
      queryDataRef.current = {
        ...queryDataRef.current,
        page: queryDataRef.current.page + 1,
      };
      await getList();
    }
  }, [getList, list.length, total]);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <>
      <div className={styles.date}>
        {t("worker.effectiveDate")}：
        <DatePicker
          value={effectiveDate}
          onChange={onChangeDate}
          allowClear={false}
        />
      </div>
      <InfiniteScroll dataLength={list.length} next={onLoadMore} hasMore={total > list.length}>
        {list?.length ? (
          <div className={styles.cards}>
            <Row>
              {list.map((data, key) => {
                return (
                  <Col key={key} md={6} xs={24} className={styles.col}>
                    <div className={styles.card}>
                      <Image
                        className={styles.cardImage}
                        preview={false}
                        src={data.photo || avatar}
                        fallback={avatar}
                      />
                      <div className={styles.cardContent}>
                        <div>
                          {t("worker.code")}: {data.code}
                        </div>
                        <div>
                          {t("worker.nationality")}: {data.nationality}
                        </div>
                        <div>
                          {t("worker.name")}: {data.name}
                        </div>
                        <div>
                          {t("worker.age")}: {getAge(data.birthday)}
                        </div>
                        <div>
                          {t("chineseZodiac.chineseZodiac")}:{" "}
                          {getZodiac(data.birthday)}
                        </div>
                        <div>
                          {t("constellation.constellation")}:{" "}
                          {getConstellation(data.birthday)}
                        </div>
                        <div>
                          {t(
                            "worker.taking care of infants, elderly, bedridden"
                          )}
                          : {data.acceptSpecialRequirements ? "✅" : "❌"}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        ) : (
          <Empty style={{ padding: "100px 0" }} />
        )}
      </InfiniteScroll>
    </>
  );
};
export default Content;
