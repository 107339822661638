import React, { useRef, useCallback, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Radio,
  DatePicker,
  Checkbox,
  Modal,
  message,
  Upload,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { workerInfoSave, employerInfoSave } from "@/api/contact-info";
import { EMPLOYER, WORKER } from "../../i18n";
import styles from "./index.module.less";

const Component = ({ role, setRole, isMobile }) => {
  const formRef = useRef(null);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const menus = [
    {
      label: t("form.employerTitle"),
      key: EMPLOYER,
    },
    {
      label: t("form.workerTitle"),
      key: WORKER,
    },
  ];

  const nationalityOptions = [
    { label: t("form.Philippines"), value: 1 },
    { label: t("form.Indonesia"), value: 2 },
  ];

  const specialOptions = [
    { label: t("form.infant"), value: 1 },
    { label: t("form.elderly"), value: 2 },
    { label: t("form.bedridden"), value: 3 },
  ];

  const accommodationOptions = [
    { label: t("form.independent"), value: 1 },
    { label: t("form.shared"), value: 2 },
    { label: t("form.none provided"), value: 3 },
  ];

  const acceptOptions = [
    { label: t("form.Yes"), value: 1 },
    { label: t("form.No"), value: 0 },
  ];

  const submit = useCallback(async () => {
    try {
      const values = await formRef.current.validateFields();
      setLoading(true);
      const {
        accommodation,
        specialRequirements,
        startDate,
        name,
        birthday,
        currentContractPeriod,
        acceptSpecialRequirements,
        photo,
        inviterPhone,
        ...rest
      } = values;
      let data = { ...rest };
      if (role === EMPLOYER) {
        data = {
          ...rest,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          accommodation: accommodation.join(","),
          specialRequirements: specialRequirements.join(","),
          inviterPhone: inviterPhone || "",
        };
      } else {
        const _photo = photo?.fileList?.[0]?.response?.url || ''
        data = {
          ...rest,
          photo: _photo,
          name,
          birthday: moment(birthday).format("YYYY-MM-DD"),
          acceptSpecialRequirements,
          inviterPhone: inviterPhone || "",
        };
      }
      if (currentContractPeriod && currentContractPeriod.length === 2) {
        data = {
          ...data,
          currentContractStart: moment(currentContractPeriod[0]).format(
            "YYYY-MM-DD"
          ),
          currentContractEnd: moment(currentContractPeriod[1]).format(
            "YYYY-MM-DD"
          ),
        };
      }
      console.log(data);
      await (role === EMPLOYER ? employerInfoSave(data) : workerInfoSave(data));
      Modal.success({
        title: t(
          "form.Submit successfully, please wait for us to contact you!"
        ),
      });
      // formRef.current.resetFields();
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [role, t]);

  return (
    <div className={styles.form}>
      <h2
        style={{
          fontSize: 32,
          width: "100%",
          textAlign: "center",
          padding: isMobile ? "20px 0" : "40px 0",
        }}
      >
        {t("form.title")}
      </h2>
      <Row className={styles.row}>
        <Col md={12} xs={24} className={styles.formCard}>
          <div className={styles.formMenus}>
            {menus.map((menu) => (
              <Button
                className={styles.formMenu}
                icon={menu.icon}
                shape="round"
                size="large"
                type="primary"
                key={menu.key}
                ghost={role !== menu.key}
                onClick={() => setRole(menu.key)}
              >
                {menu.label}
              </Button>
            ))}
          </div>
          {role === EMPLOYER ? (
            <Form layout="vertical" ref={formRef}>
              <Form.Item
                label={t("form.Nationality")}
                name="nationality"
                rules={[
                  {
                    required: true,
                    message: t("form.Please select the nationality"),
                  },
                ]}
              >
                <Radio.Group options={nationalityOptions} />
              </Form.Item>
              <Form.Item
                label={t("form.Current contract period")}
                name="currentContractPeriod"
              >
                <DatePicker.RangePicker />
              </Form.Item>
              <Form.Item
                label={t("form.Desired start date of employment")}
                name="startDate"
                rules={[
                  {
                    required: true,
                    message: t("form.Please select the start date"),
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) =>
                    current && current < moment().startOf("day")
                  }
                />
              </Form.Item>
              <Form.Item
                label={t("form.Special care requirements")}
                name="specialRequirements"
              >
                <Checkbox.Group options={specialOptions} />
              </Form.Item>
              <Form.Item
                label={t("form.Accommodation options")}
                name="accommodation"
                rules={[
                  {
                    required: true,
                    message: t("form.Please select the accommodation options"),
                  },
                ]}
              >
                <Checkbox.Group options={accommodationOptions} />
              </Form.Item>
              <Form.Item
                label={t("form.Enter your phone number for matching")}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t("form.phoneError"),
                  },
                  {
                    message: t("form.phoneError"),
                    pattern: /^([6|9])\d{7}$/,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("form.inviterPhone")}
                name="inviterPhone"
                rules={[
                  {
                    message: t("form.phoneError"),
                    pattern: /^([6|9])\d{7}$/,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          ) : (
            <Form layout="vertical" ref={formRef}>
              <Form.Item
                label={t("form.Name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("form.Please enter your name"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("form.Nationality")}
                name="nationality"
                rules={[
                  {
                    required: true,
                    message: t("form.Please select the nationality"),
                  },
                ]}
              >
                <Radio.Group options={nationalityOptions} />
              </Form.Item>
              <Form.Item
                label={t("form.Birthday")}
                name="birthday"
                rules={[
                  {
                    required: true,
                    message: t("form.Please select the birthday"),
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                label={t("form.Current contract period")}
                name="currentContractPeriod"
              >
                <DatePicker.RangePicker />
              </Form.Item>
              <Form.Item
                label={t(
                  "form.Can you accept taking care of infants, elderly, bedridden"
                )}
                name="acceptSpecialRequirements"
                rules={[
                  {
                    required: true,
                    message: t(
                      "form.Please select the can you accept taking care of infants, elderly, bedridden"
                    ),
                  },
                ]}
              >
                <Radio.Group options={acceptOptions} />
              </Form.Item>
              <Form.Item
                label={t("form.Enter your phone number for matching")}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t("form.phoneError"),
                  },
                  {
                    message: t("form.phoneError"),
                    pattern: /^([6|9])\d{7}$/,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("form.inviterPhone")}
                name="inviterPhone"
                rules={[
                  {
                    message: t("form.phoneError"),
                    pattern: /^([6|9])\d{7}$/,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label={t("form.photo")} name="photo">
                <Upload
                  action="https://files-m.jmall.fun/attachment"
                  listType="picture-card"
                  accept="image/*"
                  maxCount={1}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>
              </Form.Item>
            </Form>
          )}
          <Button
            type="primary"
            size="large"
            block
            onClick={submit}
            loading={loading}
            disabled={loading}
          >
            {t("form.submit")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Component;
