/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { enquireScreen } from "enquire-js";
import { Layout } from "antd";
import {
  EMPLOYER,
} from "@/i18n";
import { template } from '@/api/page-content-template';
import ContentComponent from "./Content";
import Form from "./Form";
import Header from "@/components/Header";

const { Content } = Layout;

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [dataSource, setDataSource] = useState();
  const headerRef = useRef();

  const [role, setRole] = useState();

  const getTemplate = useCallback(async () => {
    try {
      const res = await template({
        type: role === EMPLOYER ? 1 : 2,
        pageType: 'HOME'
      });
      setDataSource(res.data || []);
    } catch (error) {
      console.log(error);
    }
  }, [role]);

  useEffect(() => {
    getTemplate();
  }, [getTemplate])

  useEffect(() => {
    enquireScreen((isMobile) => {
      setIsMobile(!!isMobile);
    });
  }, []);

  return (
    <Layout style={{ height: '100vh' }}>
      <Header ref={headerRef} onChangeRole={setRole} />
      <Content style={{ overflowY: 'auto' }}>
        <ContentComponent isMobile={isMobile} dataSource={dataSource} />
        {dataSource && <Form role={role} setRole={headerRef.current?.setRole} isMobile={isMobile} />}
      </Content>
    </Layout>
  );
};

export default Home;
