import { Layout, Space, Button, Dropdown } from "antd";
import { UserOutlined, TeamOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  languages,
  getLanguageMenus,
  EMPLOYER,
  WORKER,
  setDefaultLanguage,
  getCurrentRole,
  setCurrentRole,
} from "@/i18n";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

const { Header } = Layout;

const Component = forwardRef(({ onChangeRole, title }, ref) => {
  const { t, i18n } = useTranslation();
  const language = languages[i18n.language];
  const [role, setRole] = useState(getCurrentRole());
  const [languageMenus, setLanguageMenus] = useState([]);

  const menus = [
    {
      label: t("home.Employer"),
      key: EMPLOYER,
      icon: <UserOutlined />,
    },
    {
      label: t("home.Worker"),
      key: WORKER,
      icon: <TeamOutlined />,
    },
  ];

  const _setRole = (role) => {
    setRole(role);
    setCurrentRole(role);
  }

  useEffect(() => {
    const languageMenus = getLanguageMenus(role);
    setLanguageMenus(languageMenus);
    setDefaultLanguage(role);
    onChangeRole?.(role);
  }, [onChangeRole, role]);

  useImperativeHandle(ref, () => ({
    setRole: _setRole,
  }));


  return (
    <Header
      style={{
        background: "#FFF",
        padding: "0 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div>
        {title ? title : <Space>
          {menus.map((menu) => (
            <Button
              icon={menu.icon}
              shape="round"
              size="large"
              type={role === menu.key ? "primary" : "text"}
              key={menu.key}
              onClick={() => _setRole(menu.key)}
            >
              {menu.label}
            </Button>
          ))}
        </Space>}
      </div>
      {role === EMPLOYER && (
        <Dropdown menu={{ items: languageMenus }}>
          <Button shape="round">{language.label}</Button>
        </Dropdown>
      )}
    </Header>
  );
});

Component.displayName = "Header";

export default Component;