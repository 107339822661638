const TokenKey = 'Admin-Token'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

export function getUserInfo() {
  return localStorage.getItem('userInfo')
}

export function setUserInfo(userInfo) {
  return localStorage.setItem('userInfo', userInfo)
}

export function removeUserInfo() {
  return localStorage.removeItem('userInfo')
}
