import { Row, Col, Image, Carousel } from "antd";
import { useTranslation } from "react-i18next";
import QueueAnim from "rc-queue-anim";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import styles from "./index.module.less";
import CardImage1 from "@/asset/card-image-1.png";
import CardImage2 from "@/asset/card-image-2.png";
import CardImage3 from "@/asset/card-image-3.png";
import CardImage4 from "@/asset/card-image-4.png";
import { history } from "@/index";

const cardImage = [CardImage1, CardImage2, CardImage3, CardImage4];

const Content = (props) => {
  const { isMobile, dataSource = [] } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      <h1
        style={{
          fontSize: 32,
          width: "100%",
          textAlign: "center",
          padding: isMobile ? "16px 0" : "50px 0",
        }}
      >
        {t("home.title")}
      </h1>
      <div className={styles.cards}>
        <OverPack
          {...{
            playScale: 0.3,
          }}
        >
          <QueueAnim
            type="bottom"
            key="block"
            leaveReverse
            component={isMobile ? Carousel : Row}
            // componentProps={isMobile && { autoplay: true }}
          >
            {dataSource.map((data, key) => {
              return (
                <Col key={key} md={6} xs={24} className={styles.col} onClick={() => history.push('/worker')}>
                  <div className={styles.card}>
                    <Image
                      className={styles.cardImage}
                      src={cardImage[key % 4]}
                      preview={false}
                    />
                    <div dangerouslySetInnerHTML={{ __html: data.content }} />
                  </div>
                </Col>
              );
            })}
          </QueueAnim>
        </OverPack>
      </div>
    </div>
  );
};
export default Content;
