import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const employerLangs = ["hk", "en"];
const workerLangs = ["id"];

export const EMPLOYER = "EMPLOYER";
export const WORKER = "WORKER";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const resources = {
  hk: {
    translation: {
      home: {
        Employer: "僱主",
        Worker: "工人",
        title: "僱傭平台",
      },
      form: {
        title: "填寫資料",
        employerTitle: "我在找一名家庭傭工",
        workerTitle: "我想找份新工作",
        employerSubTitle: "填寫要求, 為你預匹配",
        workerSubTitle: "填寫要求，需要開始用工的時間，為你預匹配",
        Philippines: "菲律賓",
        Indonesia: "印尼",
        Nationality: "國籍",
        "Desired start date of employment": "想要幾號開始用工",
        "Special care requirements": "特殊照顧要求",
        infant: "嬰兒",
        elderly: "老人",
        bedridden: "臥床",
        "Accommodation options": "可以提供的住宿",
        independent: "獨立住宿",
        shared: "合用房間",
        "none provided": "無住宿提供",
        "Enter your phone number for matching": "輸入手機號碼為你匹配",
        Name: "姓名",
        Birthday: "生日",
        "Current contract period": "當前合約時間",
        "Start On": "開始於",
        "End On": "結束於",
        "Can you accept taking care of infants, elderly, bedridden":
          "是否可以接受照顾婴儿、老人、卧床",
        Yes: "是",
        No: "否",
        phoneError: "手機號格式不正確(6或9開頭的8位數字)",
        submit: "提交",
        "Please select the nationality": "請選擇國籍",
        "Please select the start date": "請選擇開始日期",
        "Please select the accommodation options": "請選擇住宿選項",
        "Please enter your phone number": "請輸入手機號碼",
        "Please enter your name": "請輸入姓名",
        "Please select the birthday": "請選擇生日",
        "Please select current period": "請選擇當前的合約時間",
        "Please select the can you accept taking care of infants, elderly, bedridden":
          "請選擇是否可以接受照顧婴儿、老人、卧床",
        "Submit successfully, please wait for us to contact you!":
          "提交成功，請等候我們与您聯繫！",
        inviterPhone: "邀請人手機號",
        photo: "照片",
      },
      worker: {
        title: "工人列表",
        effectiveDate: "有效日期",
        code: "工人編號",
        nationality: "國籍",
        name: "姓名",
        age: "年齡",
        "taking care of infants, elderly, bedridden": "照顧嬰兒、老人、臥床",
      },
      // 十二生肖
      chineseZodiac: {
        chineseZodiac: "生肖",
        rat: "鼠",
        ox: "牛",
        tiger: "虎",
        rabbit: "兔",
        dragon: "龍",
        snake: "蛇",
        horse: "馬",
        sheep: "羊",
        monkey: "猴",
        rooster: "雞",
        dog: "狗",
        pig: "豬",
      },
      // 星座
      constellation: {
        constellation: "星座",
        aquarius: "水瓶座",
        pisces: "雙魚座",
        aries: "牡羊座",
        taurus: "金牛座",
        gemini: "雙子座",
        cancer: "巨蟹座",
        leo: "獅子座",
        virgo: "處女座",
        libra: "天秤座",
        scorpio: "天蠍座",
        sagittarius: "射手座",
        capricorn: "摩羯座",
      },
    },
  },
  en: {
    translation: {
      home: {
        Employer: "Employer",
        Worker: "Worker",
        title: "Employment Platform",
      },
      form: {
        title: "Fill in the Information",
        employerTitle: "I am looking for a domestic helper",
        workerTitle: "I am looking for a new job",
        employerSubTitle: "Fill in your requirements for matching",
        workerSubTitle:
          "Fill in your requirements and desired start date for matching",
        Philippines: "Philippines",
        Indonesia: "Indonesia",
        Nationality: "Nationality",
        "Desired start date of employment": "Desired start date of employment",
        "Special care requirements": "Special care requirements",
        infant: "Infant",
        elderly: "Elderly",
        bedridden: "Bedridden",
        "Accommodation options": "Accommodation options",
        independent: "Independent",
        shared: "Shared",
        "none provided": "None provided",
        "Enter your phone number for matching":
          "Enter your phone number for matching",
        Name: "Name",
        Birthday: "Birthday",
        "Current contract period": "Current contract period",
        "Start On": "Start On",
        "End On": "End On",
        "Can you accept taking care of infants, elderly, bedridden":
          "Can you accept taking care of infants, elderly, bedridden",
        Yes: "Yes",
        No: "No",
        phoneError:
          "Invalid phone number format (8 digits starting with 6 or 9)",
        submit: "Submit",
        "Please select the nationality": "Please select the nationality",
        "Please select the start date": "Please select the start date",
        "Please select the accommodation options":
          "Please select the accommodation options",
        "Please enter your phone number": "Please enter your phone number",
        "Please enter your name": "Please enter your name",
        "Please select the birthday": "Please select the birthday",
        "Please select current period":
          "Please select the current contract period",
        "Please select the can you accept taking care of infants, elderly, bedridden":
          "Please select whether you can accept taking care of infants, elderly, bedridden",
        "Submit successfully, please wait for us to contact you!":
          "Submit successfully, please wait for us to contact you!",
        inviterPhone: "Inviter Phone Number",
        photo: "Photo",
      },
      worker: {
        title: "Worker List",
        effectiveDate: "Effective Date",
        code: "Worker Code",
        nationality: "Nationality",
        name: "Name",
        age: "Age",
        "taking care of infants, elderly, bedridden":
          "Taking care of infants, elderly, bedridden",
      },
      // 十二生肖
      chineseZodiac: {
        chineseZodiac: "Chinese Zodiac",
        rat: "Rat",
        ox: "Ox",
        tiger: "Tiger",
        rabbit: "Rabbit",
        dragon: "Dragon",
        snake: "Snake",
        horse: "Horse",
        sheep: "Sheep",
        monkey: "Monkey",
        rooster: "Rooster",
        dog: "Dog",
        pig: "Pig",
      },
      // 星座
      constellation: {
        constellation: "Constellation",
        aquarius: "Aquarius",
        pisces: "Pisces",
        aries: "Aries",
        taurus: "Taurus",
        gemini: "Gemini",
        cancer: "Cancer",
        leo: "Leo",
        virgo: "Virgo",
        libra: "Libra",
        scorpio: "Scorpio",
        sagittarius: "Sagittarius",
        capricorn: "Capricorn",
      },
    },
  },
  id: {
    translation: {
      home: {
        Employer: "Pemberi pekerjaan",
        Worker: "Pekerja",
        title: "Platform kerja",
      },
      form: {
        title: "Isi Informasi",
        employerTitle: "Saya mencari pembantu rumah tangga",
        workerTitle: "Saya mencari pekerjaan baru",
        employerSubTitle: "Isi persyaratan untuk pencocokan",
        workerSubTitle:
          "Isi persyaratan dan tanggal mulai yang diinginkan untuk pencocokan",
        Philippines: "Filipina",
        Indonesia: "Indonesia",
        Nationality: "Negara",
        "Desired start date of employment":
          "Tanggal mulai kerja yang diinginkan",
        "Special care requirements": "Persyaratan perawatan khusus",
        infant: "Bayi",
        elderly: "Lansia",
        bedridden: "Terbaring",
        "Accommodation options": "Opsi akomodasi",
        independent: "Independen",
        shared: "Berbagi",
        "none provided": "Tidak ada akomodasi yang disediakan",
        "Enter your phone number for matching":
          "Masukkan nomor telepon Anda untuk pencocokan",
        Name: "Nama",
        Birthday: "Tanggal lahir",
        "Current contract period": "Periode kontrak saat ini",
        "Start On": "Dimulai pada",
        "End On": "Berakhir pada",
        "Can you accept taking care of infants, elderly, bedridden":
          "Apakah Anda dapat menerima merawat bayi, lansia, terbaring",
        Yes: "Ya",
        No: "Tidak",
        phoneError:
          "Format nomor telepon tidak valid (8 digit dimulai dengan 6 atau 9)",
        submit: "Kirim",
        "Please select the nationality": "Silakan pilih negara",
        "Please select the start date": "Silakan pilih tanggal mulai",
        "Please select the accommodation options":
          "Silakan pilih opsi akomodasi",
        "Please enter your phone number": "Silakan masukkan nomor telepon Anda",
        "Please enter your name": "Silakan masukkan nama Anda",
        "Please select the birthday": "Silakan pilih tanggal lahir",
        "Please select current period":
          "Silakan pilih periode kontrak saat ini",
        "Please select the can you accept taking care of infants, elderly, bedridden":
          "Silakan pilih apakah Anda dapat menerima merawat bayi, lansia, terbaring",
        "Submit successfully, please wait for us to contact you!":
          "Kirim berhasil, harap tunggu kami menghubungi Anda!",
        inviterPhone: "Nomor telepon pemberi tahu",
        photo: "Foto",
      },
      worker: {
        title: "Daftar Pekerja",
        effectiveDate: "Tanggal Efektif",
        code: "Kode Pekerja",
        nationality: "Negara",
        name: "Nama",
        age: "Umur",
        "taking care of infants, elderly, bedridden":
          "Merawat bayi, lansia, terbaring",
      },
      // 十二生肖
      chineseZodiac: {
        chineseZodiac: "Zodiak Cina",
        rat: "Tikus",
        ox: "Sapi",
        tiger: "Harimau",
        rabbit: "Kelinci",
        dragon: "Naga",
        snake: "Ular",
        horse: "Kuda",
        sheep: "Domba",
        monkey: "Monyet",
        rooster: "Ayam",
        dog: "Anjing",
        pig: "Babi",
      },
      // 星座
      constellation: {
        constellation: "Konstelasi",
        aquarius: "Aquarius",
        pisces: "Pisces",
        aries: "Aries",
        taurus: "Taurus",
        gemini: "Gemini",
        cancer: "Kanker",
        leo: "Leo",
        virgo: "Virgo",
        libra: "Libra",
        scorpio: "Kalajengking",
        sagittarius: "Sagittarius",
        capricorn: "Capricornus",
      },
    },
  },
};

export const languages = {
  hk: {
    label: "繁體中文",
    value: "hk",
    id: 2,
  },
  en: {
    label: "English",
    value: "en",
    id: 3,
  },
  id: {
    label: "Bahasa Indonesia",
    value: "id",
    id: 4,
  },
};

export const getCurrentRole = () => {
  const hash = window.location.hash;
  if (hash.indexOf(WORKER) > -1) {
    return WORKER;
  }
  return EMPLOYER;
};

export const setCurrentRole = (role) => {
  window.location.hash = `#${role}`;
};

export function getLanguage() {
  // 获取角色
  const role = getCurrentRole();
  const roleLangs = role === WORKER ? workerLangs : employerLangs;
  const chooseLanguage = localStorage.getItem("language");
  if (roleLangs.includes(chooseLanguage)) return chooseLanguage;
  // 浏览器语言
  const language = (
    navigator.language || navigator.browserLanguage
  ).toLowerCase();
  const locales = Object.keys(languages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1 && roleLangs.includes(locale)) {
      localStorage.setItem("language", locale);
      localStorage.setItem("languageid", languages[locale].id);
      return locale;
    }
  }
  // 默认设置角色所有的语言中的第一个
  const lang = roleLangs[0];
  localStorage.setItem("language", lang);
  localStorage.setItem("languageid", languages[lang].id);
  return lang;
}

export function setLanguage(lang) {
  i18n.changeLanguage(lang);
  localStorage.setItem("language", lang);
  localStorage.setItem("languageid", languages[lang].id);
}

export const setDefaultLanguage = (role) => {
  const langKeys = role === EMPLOYER ? employerLangs : workerLangs;
  const language = getLanguage();
  if (!langKeys.includes(language)) {
    setLanguage(langKeys[0]);
  } else {
    setLanguage(language);
  }
};

export const getLanguageMenus = (role) => {
  const langKeys = role === EMPLOYER ? employerLangs : workerLangs;
  return Object.keys(languages)
    .filter((key) => langKeys.includes(key))
    .map((key) => ({
      ...languages[key],
      label: (
        <div
          onClick={() => {
            setLanguage(key);
          }}
        >
          {languages[key].label}
        </div>
      ),
    }));
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLanguage(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
