
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './page/Home';
import Worker from './page/Worker';
import "./less/antMotionStyle.less";

function App() {
  return (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/worker" element={<Worker />} />

  </Routes>
  );
}

export default App;
