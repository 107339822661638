/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React, { useState, useEffect, useRef } from "react";
import { enquireScreen } from "enquire-js";
import { Layout } from "antd";
import ContentComponent from "./Content";
import Header from "@/components/Header";
import { useTranslation } from "react-i18next";

const { Content } = Layout;

const Home = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const headerRef = useRef();

  useEffect(() => {
    enquireScreen((isMobile) => {
      setIsMobile(!!isMobile);
    });
  }, []);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        ref={headerRef}
        title={
          <h1
            style={{
              fontSize: isMobile ? 24 : 32,
              width: "100%",
              textAlign: "center",
              padding: isMobile ? "16px 0" : "50px 0",
            }}
          >
            {t("worker.title")}
          </h1>
        }
      />
      <Content>
        <ContentComponent isMobile={isMobile} />
      </Content>
    </Layout>
  );
};

export default Home;
