
import moment from 'moment'
import i18n from '@/i18n'

// 定义十二生肖数组
const zodiacs = [
  i18n.t('chineseZodiac.rat'),
  i18n.t('chineseZodiac.ox'),
  i18n.t('chineseZodiac.tiger'),
  i18n.t('chineseZodiac.rabbit'),
  i18n.t('chineseZodiac.dragon'),
  i18n.t('chineseZodiac.snake'),
  i18n.t('chineseZodiac.horse'),
  i18n.t('chineseZodiac.sheep'),
  i18n.t('chineseZodiac.monkey'),
  i18n.t('chineseZodiac.rooster'),
  i18n.t('chineseZodiac.dog'),
  i18n.t('chineseZodiac.pig')
]

// 定义星座数组
const horoscopes = [
  { name: i18n.t('constellation.capricorn'), start: [12, 22], end: [1, 19] },
  { name: i18n.t('constellation.aquarius'), start: [1, 20], end: [2, 18] },
  { name: i18n.t('constellation.pisces'), start: [2, 19], end: [3, 20] },
  { name: i18n.t('constellation.aries'), start: [3, 21], end: [4, 19] },
  { name: i18n.t('constellation.taurus'), start: [4, 20], end: [5, 20] },
  { name: i18n.t('constellation.gemini'), start: [5, 21], end: [6, 21] },
  { name: i18n.t('constellation.cancer'), start: [6, 22], end: [7, 22] },
  { name: i18n.t('constellation.leo'), start: [7, 23], end: [8, 22] },
  { name: i18n.t('constellation.virgo'), start: [8, 23], end: [9, 22] },
  { name: i18n.t('constellation.libra'), start: [9, 23], end: [10, 23] },
  { name: i18n.t('constellation.scorpio'), start: [10, 24], end: [11, 22] },
  { name: i18n.t('constellation.sagittarius'), start: [11, 23], end: [12, 21] }
]

// 计算生肖
export function getZodiac(date) {
  const year = moment(date).year()
  return zodiacs[(year - 4) % 12]
}

// 计算星座
export function getConstellation(date) {
  const _date = moment(date)
  const month = _date.month() + 1
  const day = _date.date()
  const current = [month, day]
  let result = ''
  horoscopes.forEach(horoscope => {
    const { start, end } = horoscope
    if ((current[0] === start[0] && current[1] >= start[1]) || (current[0] === end[0] && current[1] <= end[1])) {
      result = horoscope.name
    }
  })
  return result
}

// 计算年龄
export function getAge(date) {
  const _date = moment(date)
  const now = moment()
  const year = now.year() - _date.year()
  const month = now.month() - _date.month()
  const day = now.date() - _date.date()
  if (month < 0 || (month === 0 && day < 0)) {
    return year - 1
  }
  return year
}

// 使用示例
// const birthday = moment('1990-04-15')
// console.log(`生肖：${getZodiac(birthday)}`)
// console.log(`星座：${getConstellation(birthday)}`)
// console.log(`年龄：${getAge(birthday)}`)
