import axios from 'axios'
import { message } from 'antd';
import { getToken, removeToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: '' // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['languageid'] = localStorage.getItem('languageid') || ''
    config.headers['language'] = localStorage.getItem('language') || ''
    const token = getToken();
    if (token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['token'] = getToken() ? `Bearer ${getToken()}` : ''
      config.headers['authorization'] = getToken() ? `Bearer ${getToken()}` : ''
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom status
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom status is not 20000, it is judged as an error.
    // console.log(typeof res)

    if (res instanceof Blob || (String(res.status) === '200' || String(res.status) === '1')) {
      return res
    } else {
      // 登录失效，超时
      if (String(res.status) === '401') {
        removeToken()
      } else {
        message.error(res.message || 'Error')
      }
      return Promise.reject(new Error(res.message || 'Error'))
    }
  },
  error => {
    console.log('err' + error) // for debug
    message.error(error.response?.data?.message ? error.response.data.message : error?.message ? error.message : 'Error')
    return Promise.reject(error)
  }
)

export default service
